<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12">
        <div class="ml-2 mb-3">
          <div class="row">
            <div class="col-md-7" v-loading="loading.detail">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center border-bottom pb-3">
                    <h5 class="mr-4">Flow Data</h5>
                    <div class="d-flex">
                      <el-button type="warning" size="small" @click="back">Back</el-button>
                      <el-button type="primary" size="small" @click="saveFlowData" :loading="loading.submit">Save & Preview</el-button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <el-form label-position="left" label-width="120px">
                        <el-form-item label="Name" class="mb-2">
                          <el-input v-model="flow.name" placeholder="Please input flow name" maxlength="30"></el-input>
                        </el-form-item>
                        <el-form-item label="Categories" class="mb-0">
                          <el-select style="width:100%" v-model="flow.categories" placeholder="Choose the values which represent your business use case" multiple>
                            <el-option v-for="(ct, idx) in categories" :label="ct" :key="idx" :value="ct"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                      <el-form>
                        <el-collapse v-model="active.form">
                          <el-collapse-item name="endpoint">
                            <template slot="title">
                              <b>Endpoint</b>
                            </template>
                            <el-form-item label="" class="mb-2">
                              <el-input v-model="endpoint_uri" placeholder="Please input endpoint uri (optional)"></el-input>
                            </el-form-item>
                          </el-collapse-item>
                        </el-collapse>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mt-2" >
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5>Edit Content</h5>
                    <!-- <el-button class="float-right" size="small" type="primary" plain @click="previewScreen()">
                      Save & Preview
                    </el-button> -->
                  </div>
                  <div class="row mt-2" v-if="alert.message.length > 0">
                    <div class="col-md-12">
                      <b-alert variant="danger" :show="alert.message.length > 0">{{ alert.message.join('\n') }}</b-alert>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12">
                      <el-tabs v-model="active.screen" type="card" closable @tab-remove="removeScreen">
                        <el-tab-pane
                          v-for="(item, idx) in form.screens"
                          :key="idx" :label="item.title" :name="item.id"
                        >
                          <div class="card card-list border mt-2 mr-4 p-1">
                            <div class="card-list-body p-2">
                              <p><b>Screen Name</b></p>
                              <div class="my-2">
                                <el-input
                                  size="small"
                                  placeholder="Please input screen name"
                                  v-model="item.title"
                                >
                                </el-input>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="card card-list border mt-2 mr-4 p-1"
                              v-for="(child, idxchild) in item.layout.children" :key="idxchild"
                              draggable @dragstart="startDrag($event, idxchild, idx)"
                              >
                              <div class="card-list-body p-2 cursor-pointer" v-if="!['If', 'Footer'].includes(child.type)"
                                @drop="onDrop($event, idxchild)" @dragover.prevent @dragenter.prevent>
                                <div class="d-flex align-items-center">
                                  <div class="d-flex">
                                    <FeatherIcon style="margin-left: -5px" type="more-vertical" class="link-icon feather-10"/>
                                    <FeatherIcon style="margin-left: -8px" type="more-vertical" class="link-icon feather-10"/>
                                  </div>
                                  <p><b>{{ getContent(child.type)?.child.label }}</b></p>
                                </div>
                                <div class="my-2" v-if="getContent(child.type)?.value === 'text'">
                                  <el-input
                                    size="small"
                                    type="textarea"
                                    placeholder="Please input text"
                                    v-model="child.text"
                                    :maxlength="getContent(child.type)?.child.max"
                                    show-word-limit
                                  ></el-input>
                                  <el-button @click="removeContent(item, idxchild)" type="danger" class="p-1 mt-2 float-right" icon="el-icon-delete"></el-button>
                                </div>
                                <div class="my-2" v-if="getContent(child.type)?.value === 'media'">
                                  <div class="row">
                                    <div class="col-md-12 mb-3">
                                      <b-file accept=".jpeg, .jpg, .png" v-model="child.src_file" @input="handleUpload(child)"/>
                                      <small class="text-primary" v-if="child.src">File Choosen</small>
                                      <br>
                                      <el-image
                                        class="mt-2"
                                        v-if="child.src"
                                        style="width: 100px; height: 100px"
                                        :src="child.src">
                                      </el-image>
                                    </div>
                                    <div class="col-md-6">
                                      <el-input
                                        size="small"
                                        placeholder="Input image height"
                                        v-model="child.height"
                                        :maxlength="getContent(child.type)?.child.max"
                                        show-word-limit
                                      ></el-input>
                                    </div>
                                    <div class="col-md-6">
                                      <el-input
                                        size="small"
                                        placeholder="Input image width"
                                        v-model="child.width"
                                        :maxlength="getContent(child.type)?.child.max"
                                        show-word-limit
                                      ></el-input>
                                    </div>
                                  </div>
                                  <el-button @click="removeContent(item, idxchild)" type="danger" class="p-1 mt-2 float-right" icon="el-icon-delete"></el-button>
                                </div>
                                <div class="my-2" v-else-if="getContent(child.type)?.value === 'text_answer'">
                                  <div v-if="getContent(child.type)?.child.value === 'TextInput'">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <el-select size="small" v-model="child['input-type']" placeholder="Please select type">
                                          <el-option v-for="(tp, type_idx) in text_type" :key="type_idx" :label="upperCaseFirst(tp)" :value="tp"></el-option>
                                        </el-select>
                                      </div>
                                      <div class="col-md-8">
                                        <el-input
                                          size="small"
                                          type="text"
                                          placeholder="Please input label"
                                          v-model="child.label"
                                          @input="setName(child)"
                                          :maxlength="getContent(child.type)?.child.max"
                                          show-word-limit
                                        >
                                        </el-input>
                                        <small v-if="child.dinamic == true">Dynamic parameter : {{ `value_${child.name}` }}</small>
                                      </div>
                                      <div class="col-md-4">
                                        <el-checkbox v-model="child.dinamic" class="mb-1 mt-2">Default Value</el-checkbox>
                                      </div>
                                      <div class="col-md-8 mt-1" v-if="child.dinamic == true">
                                        <el-input
                                          size="small"
                                          placeholder="Please input example value"
                                          v-model="child.example"
                                          :type="['text', 'email', 'number'].includes(child['input-type']) ? child['input-type'] : 'text'"
                                        >
                                        </el-input>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else-if="getContent(child.type)?.child.value === 'TextArea'">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <el-input
                                          size="small"
                                          placeholder="Please input label"
                                          v-model="child.label"
                                          @input="setName(child)"
                                          :maxlength="getContent(child.type)?.child.max"
                                          show-word-limit
                                        >
                                        </el-input>
                                        <small v-if="child.dinamic == true">Dynamic parameter : {{ `value_${child.name}` }}</small>
                                      </div>
                                      <div class="col-md-4 mt-1">
                                        <el-checkbox v-model="child.dinamic" class="mb-1 mt-2">Default Value</el-checkbox>
                                      </div>
                                      <div class="col-md-8 mt-2" v-if="child.dinamic == true">
                                        <el-input
                                          size="small"
                                          placeholder="Please input example value"
                                          v-model="child.example"
                                          type="textarea"
                                        >
                                        </el-input>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else-if="['CalendarPicker', 'DatePicker'].includes(getContent(child.type)?.child.value)">
                                    <div class="row" v-if="getContent(child.type)?.child.value === 'CalendarPicker'">
                                      <div class="col-md-12">
                                        <el-select
                                          size="small"
                                          v-model="child.mode"
                                          placeholder="Select mode calendar"
                                          @change="calendarMode(child)"
                                        >
                                          <el-option label="Single" value="single"></el-option>
                                          <el-option label="Range" value="range"></el-option>
                                        </el-select>
                                      </div>
                                    </div>
                                    <div class="row mt-2" v-if="child.mode === 'range'">
                                      <div class="col-md-6">
                                        <span>Start date</span>
                                        <el-input
                                          size="small"
                                          placeholder="Input label start"
                                          v-model="child.label['start-date']"
                                          @input="setName(child)"
                                          :maxlength="getContent(child.type)?.child.max"
                                          show-word-limit
                                        >
                                        </el-input>
                                        <el-input
                                          class="my-1"
                                          size="small"
                                          placeholder="Input instructions start (optional)"
                                          v-model="child['helper-text']['start-date']"
                                          :maxlength="80"
                                          show-word-limit
                                        >
                                        </el-input>
                                        <el-switch v-model="child.required['start-date']" active-text="Required start"></el-switch>
                                      </div>
                                      <div class="col-md-6">
                                        <span>End date</span>
                                        <el-input
                                          size="small"
                                          placeholder="Input label end"
                                          v-model="child.label['end-date']"
                                          :maxlength="getContent(child.type)?.child.max"
                                          show-word-limit
                                        >
                                        </el-input>
                                        <el-input
                                          class="my-1"
                                          size="small"
                                          placeholder="Input instructions end (optional)"
                                          v-model="child['helper-text']['end-date']"
                                          :maxlength="80"
                                          show-word-limit
                                        >
                                        </el-input>
                                        <el-switch v-model="child.required['end-date']" active-text="Required end"></el-switch>
                                      </div>
                                    </div>
                                    <div class="row" v-else>
                                    <!-- <div class="row"> -->
                                      <div class="col-md-12 mt-2">
                                        <el-input
                                          size="small"
                                          placeholder="Please input label"
                                          v-model="child.label"
                                          @input="setName(child)"
                                          :maxlength="getContent(child.type)?.child.max"
                                          show-word-limit
                                        >
                                        </el-input>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-12 mt-2">
                                        <span>Set minimal and maximal date for choosen (optional)</span>
                                      </div>
                                      <div class="col-md-6">
                                        <el-date-picker type="date"
                                        placeholder="Choose min date" v-model="child['min-date']"
                                        style="width: 100%;" size="small"></el-date-picker>
                                      </div>
                                      <div class="col-md-6">
                                        <el-date-picker type="date"
                                        placeholder="Choose max date" v-model="child['max-date']"
                                        style="width: 100%;" size="small"></el-date-picker>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="child.mode !== 'range'" class="mt-2">
                                    <el-input
                                      size="small"
                                      placeholder="Please input instructions (optional)"
                                      v-model="child['helper-text']"
                                      maxlength="80"
                                      show-word-limit
                                    >
                                    </el-input>
                                  </div>
                                  <div class="d-flex justify-content-between mt-2">
                                    <el-switch v-if="child.mode !== 'range'" v-model="child.required" active-text="Required"></el-switch>
                                    <el-button @click="removeContent(item, idxchild)" type="danger" class="p-1" icon="el-icon-delete"></el-button>
                                  </div>
                                </div>
                                <div class="my-2" v-else-if="getContent(child.type)?.value === 'selection'">
                                  <div v-if="['RadioButtonsGroup', 'CheckboxGroup', 'Dropdown'].includes(getContent(child.type)?.child.value)">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <el-input
                                          size="small"
                                          type="text"
                                          placeholder="Please input label"
                                          v-model="child.label"
                                          @input="setName(child)"
                                          :maxlength="getContent(child.type)?.child.max"
                                          show-word-limit
                                        >
                                        </el-input>
                                        <small v-if="child.dinamic == true">Dynamic parameter : {{ `value_${child.name}` }}</small>
                                      </div>
                                    </div>
                                    <el-input
                                      class="my-2"
                                      size="small"
                                      placeholder="Please input descriptions (optional)"
                                      v-model="child.description"
                                      maxlength="80"
                                      show-word-limit
                                    ></el-input>

                                    <el-checkbox v-model="child.dinamic" class="mb-1 mt-2">Dinamic Value</el-checkbox>
                                    <div class="row">
                                      <span class="col-md-10" v-if="child.dinamic">Example Value</span>
                                      <span class="col-md-10" v-else>Options</span>
                                      <div class="col-md-2">
                                        <el-button @click="addOptions(child)" type="primary" plain class="p-1 mt-1" icon="el-icon-plus"></el-button>
                                      </div>
                                    </div>
                                    <div class="row my-1"
                                      v-for="(op, opidx) in child['data-source']"
                                      :key="opidx">
                                      <div class="col-md-10">
                                        <el-input
                                          size="small"
                                          type="text"
                                          placeholder="Input option label"
                                          v-model="op.title"
                                          @input="setOptionsId(op)"
                                          :maxlength="getContent(child.type)?.child.max"
                                          show-word-limit
                                        >
                                        </el-input>
                                      </div>
                                      <div class="col-md-2">
                                        <el-button type="default" class="p-1 mt-1" icon="el-icon-delete"
                                        :disabled="child['data-source'].length <= 2" @click="removeOptions(child, opidx)"></el-button>
                                      </div>
                                    </div>
                                    <div class="row mb-2"></div>
                                  </div>
                                  <div v-else-if="getContent(child.type)?.child.value === 'OptIn'">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <el-input
                                          size="small"
                                          type="textarea"
                                          placeholder="Please input information"
                                          v-model="child.label"
                                          :maxlength="getContent(child.type)?.child.max"
                                          show-word-limit
                                        >
                                        </el-input>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-between mt-2">
                                    <el-switch v-model="child.required" active-text="Required"></el-switch>
                                    <el-button @click="removeContent(item, idxchild)" type="danger" class="p-1" icon="el-icon-delete"></el-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card card-list border mt-2 mr-4 p-1">
                              <div class="card-list-body p-2">
                                <p><b> Button</b></p>
                                <div class="my-2">
                                  <el-input
                                    size="small"
                                    placeholder="Please input label"
                                    v-model="button[idx].label"
                                    maxlength="30"
                                    show-word-limit
                                  >
                                  </el-input>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr>
                          <div class="mt-2" v-if="item.layout.children.length < 50">
                            <el-cascader
                              style="width: 50%;"
                              clearable
                              placeholder="Select to add content"
                              v-model="screen.content"
                              :options="content"
                              :props="{ expandTrigger: 'hover' }"
                              @change="addContent(item, idx)"
                            >
                            </el-cascader>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane name="add" v-if="form.screens.length < 10">
                          <span slot="label"><b><i class="fa fa-plus"></i> Add new screen</b></span>
                          <div class="d-flex mt-2 mr-4">
                            <el-input v-model="screen.name" size="small" class="mr-2" placeholder="Input screen name"></el-input>
                            <el-button size="small" type="primary" class="float-right" @click="addScreen()">Add</el-button>
                          </div>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="card card-list border-primary p-1" style="min-height: 850px;">
                <div class="card-list-body p-2">
                  <div v-loading="loading.preview">
                    <div v-if="preview_data.preview" class="p-1">
                      <iframe style="min-height: 845px; width: 100%" :src="preview_data.preview.preview_url" title="Preview"></iframe>
                    </div>
                    <div v-else>
                      <el-empty :image-size="200" description="No preview" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-template-curly-in-string */
import { upperFirst, cloneDeep } from 'lodash';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import popupErrorMessages from '@/library/popup-error-messages';
import flowsAPI from '../../../api/flows';
import base64File from '../../../library/base64File';

export default {
  name: 'FlowCreate',
  metaInfo() {
    return {
      title: 'Create Flow',
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        description: {
          required,
        },
      },
    };
  },
  data() {
    return {
      active: {
        form: '',
        screen: 'FORM_SCREEN_A',
      },
      loading: {
        submit: false,
        preview: false,
      },
      alert: {
        message: [],
      },
      screen: {
        name: '',
        count: 2,
        content: [],
      },
      categories: [
        'SIGN_UP',
        'SIGN_IN',
        'APPOINTMENT_BOOKING',
        'LEAD_GENERATION',
        'CONTACT_US',
        'CUSTOMER_SUPPORT',
        'SURVEY',
        'OTHER',
      ],
      text_type: ['text', 'number', 'email', 'password', 'passcode', 'phone'],
      flow: {
        name: '',
        categories: [],
      },
      endpoint_uri: '',
      content: [
        {
          value: 'text',
          label: 'Text',
          children: [
            {
              value: 'TextHeading',
              label: 'Large Heading',
              max: 80,
            },
            {
              value: 'TextSubheading',
              label: 'Small Heading',
              max: 80,
            },
            {
              value: 'TextCaption',
              label: 'Caption',
              max: 409,
            },
            {
              value: 'TextBody',
              label: 'Body',
              max: 4096,
            },
          ],
        },
        {
          value: 'media',
          label: 'Media',
          children: [
            {
              value: 'Image',
              label: 'Image',
            },
          ],
        },
        {
          value: 'text_answer',
          label: 'Text Answer',
          children: [
            {
              value: 'TextInput',
              label: 'Short Answer',
              max: 20,
            },
            {
              value: 'TextArea',
              label: 'Paragraph',
              max: 30,
            },
            {
              value: 'DatePicker',
              label: 'Date picker',
              max: 20,
            },
            {
              value: 'CalendarPicker',
              label: 'Calendar picker',
              max: 20,
            },
          ],
        },
        {
          value: 'selection',
          label: 'Selection',
          children: [
            {
              value: 'RadioButtonsGroup',
              label: 'Single Choice',
              max: 30,
            },
            {
              value: 'CheckboxGroup',
              label: 'Multiple Choice',
              max: 30,
            },
            {
              value: 'Dropdown',
              label: 'Dropdown',
              max: 30,
            },
            {
              value: 'OptIn',
              label: 'Opt-in',
              max: 300,
            },
          ],
        },
      ],
      count_optIn: 0,
      button: [
        {
          type: 'Footer',
          label: 'Next',
          'on-click-action': {
            name: 'complete',
            payload: {},
          },
        },
      ],
      flow_id: '',
      preview_data: {},
      form: {
        version: '6.1',
        screens: [
          {
            id: 'FORM_SCREEN_A',
            title: 'Form 1',
            data: {},
            layout: {
              type: 'SingleColumnLayout',
              children: [
                {
                  type: 'TextBody',
                  text: 'Silakan isi data berikut ini ya',
                },
                {
                  type: 'TextInput',
                  label: 'Name',
                  name: 'name',
                  example: '',
                  required: true,
                  dinamic: false,
                  'input-type': 'text',
                  'helper-text': '',
                },
              ],
            },
          },
        ],
      },
      loaderStack: 0,
      loader: null,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
  },
  methods: {
    initials(string) {
      return (string || '').split(' ').map((n) => n[0]).join('').slice(0, 2);
    },
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    back() {
      this.$router.back();
    },
    numberToAlpha(num) {
      const str = String.fromCharCode(parseInt(num + 64));
      return str;
    },
    setName(dt) {
      if (dt.type === 'CalendarPicker' && dt.mode === 'range') {
        const name = dt.label['start-date'].replace(/ |-/gi, '_');
        dt.name = name.toLowerCase();
      } else {
        const name = dt.label.replace(/ |-/gi, '_');
        dt.name = name.toLowerCase();
      }
    },
    setOptionsId(dt) {
      // const name = dt.title.replace(/ |-/gi, '_');
      // dt.id = name.toLowerCase();
      dt.id = dt.title;
    },
    calendarMode(child) {
      if (child.type === 'CalendarPicker' && child.mode === 'range') {
        child.label = {
          'start-date': '',
          'end-date': '',
        };
        child.required = {
          'start-date': true,
          'end-date': true,
        };
        child['helper-text'] = {
          'start-date': '',
          'end-date': '',
        };
      } else {
        child.label = '';
        child.required = true;
        child['helper-text'] = '';
      }
    },
    getContent(val) {
      const dt = this.content.find((v) => v.children.find((x) => x.value === val)?.value);
      const child = dt.children.find((v) => v.value === val);
      return {
        value: dt.value,
        label: dt.label,
        child,
      };
    },
    addScreen() {
      const obj = {
        id: `FORM_SCREEN_${this.numberToAlpha(this.screen.count)}`,
        title: this.screen.name,
        data: {},
        layout: {
          type: 'SingleColumnLayout',
          children: [],
        },
      };
      this.form.screens.push(obj);
      this.screen.name = '';
      this.screen.count += 1;
      this.active.screen = obj.id;
      this.button.push({
        type: 'Footer',
        label: '',
        'on-click-action': {
          name: 'navigate',
          payload: {},
        },
      });
    },
    addContent(dt, idx) {
      if (this.screen.content.length > 0) {
        const type = this.screen.content[1];
        let obj = {};
        if (['TextHeading', 'TextSubheading', 'TextBody', 'TextCaption'].includes(type)) {
          obj = {
            type,
            text: '',
          };
        } else if (['TextInput', 'TextArea'].includes(type)) {
          obj = {
            type,
            label: '',
            name: '',
            example: '',
            required: true,
            dinamic: false,
            'helper-text': '',
          };
          if (type === 'TextInput') {
            obj['input-type'] = 'text';
          }
        } else if (['DatePicker', 'CalendarPicker'].includes(type)) {
          obj = {
            type,
            label: '', // range
            name: '',
            required: true, // range
            'helper-text': '', // range
            'min-date': '',
            'max-date': '',
          };
          if (type === 'CalendarPicker') {
            obj.mode = 'single';
          }
        } else if (['RadioButtonsGroup', 'CheckboxGroup', 'Dropdown'].includes(type)) {
          obj = {
            type,
            name: '',
            label: '',
            description: '',
            required: true,
            dinamic: false,
            count: 2,
            'data-source': [
              {
                id: 'option_1',
                title: 'Option 1',
              },
              {
                id: 'option_2',
                title: 'Option 2',
              },
            ],
          };
        } else if (['OptIn'].includes(type)) {
          this.count_optIn += 1;
          obj = {
            type,
            name: `OptIn_${this.count_optIn}`,
            label: '',
            required: true,
          };
        } else if (['Image'].includes(type)) {
          obj = {
            type,
            src: '',
            src_file: null,
            width: '',
            height: '',
          };
        }
        if (obj.type) {
          this.form.screens[idx].layout.children.push(obj);
        }
        this.screen.content = [];
      }
    },
    addOptions(child) {
      child.count += 1;
      const obj = {
        id: `option_${child.count}`,
        title: `Option ${child.count}`,
      };
      child['data-source'].push(obj);
    },
    removeOptions(child, idx) {
      child['data-source'].splice(idx, 1);
    },
    removeContent(item, idx) {
      item.layout.children.splice(idx, 1);
    },
    removeScreen(targetName) {
      if (targetName !== 'add') {
        this.$confirm(this.$t('flows.confirm.publish'), this.$t('general.confirmation'), {
          confirmButtonText: this.$t('general.yes'),
          cancelButtonText: this.$t('general.no'),
          type: 'warning',
          center: true,
          /* eslint-disable no-param-reassign */
          beforeClose: async (action, instance, cb) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              const idx = this.form.screens.findIndex((v) => v.id === targetName);
              this.form.screens.splice(idx, 1);
              this.active.screen = 'add';
            }
            instance.confirmButtonLoading = false;
            cb();
          },
        });
      }
    },
    setJSONData() {
      const form = cloneDeep(this.form);
      const dinamic_list = [];
      form.screens.forEach((v) => {
        v.layout.children.map((x) => {
          if (x.count) {
            delete x.count;
          }
          if (x.src_file) {
            delete x.src_file;
          }
          if (x['max-date'] && x['max-date'] !== '') {
            x['max-date'] = moment(x['max-date']).format('YYYY-MM-DD');
          }
          if (x['min-date'] && x['min-date'] !== '') {
            x['min-date'] = moment(x['min-date']).format('YYYY-MM-DD');
          }
          if (x.dinamic === true) {
            if (['RadioButtonsGroup', 'CheckboxGroup', 'Dropdown'].includes(x.type)) {
              dinamic_list.push({
                name: `list_${x.name}`,
                data: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      id: {
                        type: 'string',
                      },
                      title: {
                        type: 'string',
                      },
                    },
                  },
                  __example__: x['data-source'],
                },
              });
              let str = '${data.list_';
              str += x.name;
              str += '}';
              x['data-source'] = str;
              delete x.dinamic;
            } else {
              let str = '${data.value_';
              str += x.name;
              str += '}';
              x['init-value'] = str;
              dinamic_list.push({
                name: `value_${x.name}`,
                data: {
                  type: 'string',
                  __example__: x.example,
                },
              });
              delete x.dinamic;
              delete x.example;
            }
          } else if (x.dinamic === false) {
            delete x.dinamic;
            delete x.example;
          }
          return x;
        });
      });
      form.screens.map((v) => {
        v.data.flow_type = {
          type: 'string',
          __example__: 'Example',
        };
        v.data.flow_source = {
          type: 'string',
          __example__: 'Example',
        };
        dinamic_list.forEach((ls) => {
          v.data[ls.name] = ls.data;
        });
        return v;
      });
      this.button.forEach((v, idx) => {
        // define button
        const count = parseInt(idx + 1);
        const count_data = parseInt(idx - 1);
        const button_dt = cloneDeep(v);
        if (form.screens[count_data]) {
          const payload_before = form.screens[count_data].layout.children.find((x) => x.type === 'Footer');
          if (payload_before['on-click-action']) {
            const keys = Object.keys(payload_before['on-click-action']?.payload);
            const py = {};
            keys.forEach((key) => {
              let str = '${data.';
              str += key;
              str += '}';
              py[key] = str;
            });
            button_dt['on-click-action'].payload = py;
          } else {
            button_dt['on-click-action'].payload = {};
          }
        } else {
          button_dt['on-click-action'].payload = {};
        }

        if (this.button.length === count) {
          form.screens[idx].terminal = true;
          // button_dt.name = 'complete';
          button_dt['on-click-action'].name = 'complete';
          delete button_dt['on-click-action'].next;
        } else {
          delete form.screens[idx].terminal;
          // button_dt.name = 'navigate';
          button_dt['on-click-action'].name = 'navigate';
          button_dt['on-click-action'].next = {
            type: 'screen',
            name: form.screens[count].id,
          };
        }
        // define data input to payload
        form.screens[idx].layout.children.forEach((input) => {
          if (input.name) {
            let str = '${form.';
            str += input.name;
            str += '}';
            button_dt['on-click-action'].payload[input.name] = str;
            dinamic_list.forEach((ls) => {
              let str_dinamic = '${data.';
              str_dinamic += ls.name;
              str_dinamic += '}';
              button_dt['on-click-action'].payload[ls.name] = str_dinamic;
            });
            // define data payload to next screen
            if (form.screens[count]) {
              if (input.type === 'CheckboxGroup') {
                form.screens[count].data[input.name] = {
                  type: 'array',
                  items: {
                    type: 'string',
                  },
                  __example__: ['Example'],
                };
              } else {
                form.screens[count].data[input.name] = {
                  type: 'string',
                  __example__: 'Example',
                };
              }
            }
          }
        });
        button_dt['on-click-action'].payload.flow_type = '${data.flow_type}';
        button_dt['on-click-action'].payload.flow_source = '${data.flow_source}';
        form.screens[idx].layout.children.push(button_dt);

        // define data previous screen to next screen
        if (form.screens[count_data]) {
          const keys = Object.keys(form.screens[count_data].data);
          keys.forEach((dt) => {
            if (form.screens[count_data].data[dt].type === 'string') {
              form.screens[idx].data[dt] = {
                type: 'string',
                __example__: 'Example',
              };
            } else if (form.screens[count_data].data[dt].type === 'array' && form.screens[count_data].data[dt].items.type === 'string') {
              form.screens[idx].data[dt] = {
                type: 'array',
                items: {
                  type: 'string',
                },
                __example__: ['Example'],
              };
            }
          });
        }
      });
      console.log(form);
      return form;
    },
    async saveFlowData() {
      this.loading.submit = true;
      await this.saveFlow();
      // this.$message({
      //   message: this.$t('flows.success.update'),
      //   type: 'success',
      // });
      this.loading.submit = false;
    },
    async saveFlow() {
      const form = await this.setJSONData();
      const body = {
        ...this.flow,
        form_json: form,
      };
      if (this.flow_id !== '') {
        body.flow_id = this.flow_id;
      }
      if (this.endpoint_uri !== '') {
        body.endpoint_uri = this.endpoint_uri;
      }
      this.loading.preview = false;
      this.preview_data = {};
      this.alert.message = [];
      await flowsAPI.save({
        workspace_id: this.activeWorkspace._id,
        payload: body,
      }).then(async (res) => {
        await popupErrorMessages(res);
        this.flow_id = res.data.flow_id;
        this.alert.message = res.data.validation_errors.map((v) => v.message);
        this.preview_data.preview = res.data.preview;
        this.loading.preview = false;
      }).catch(async () => {
        this.loading.preview = false;
        this.loading.submit = false;
      });
    },
    // async previewScreen() {
    //   this.loading.preview = true;
    //   this.preview_data = {};
    //   await this.saveFlow();
    //   const res = await flowsAPI.preview({
    //     workspace_id: this.activeWorkspace._id,
    //     flow_id: this.flow_id,
    //   });
    //   await popupErrorMessages(res);
    //   this.preview_data = res.data;
    //   this.loading.preview = false;
    // },
    async handleUpload(child) {
      const base64 = await base64File(child.src_file);
      child.src = base64;
    },
    handleRemoveFile(child) {
      if (child.src_file[0]) {
        child.src_file = [];
        child.src = '';
      }
    },
    startDrag(evt, index_from, index_parent) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('index_parent', index_parent);
      evt.dataTransfer.setData('index_from', index_from);
    },
    onDrop(evt, index_to) {
      const index_from = parseInt(evt.dataTransfer.getData('index_from'));
      const index_parent = parseInt(evt.dataTransfer.getData('index_parent'));
      if (index_to !== index_from) {
        console.log(evt);
        console.log(index_from);
        console.log(index_to);
        console.log(index_parent);
        const child_data = cloneDeep(this.form.screens[index_parent].layout.children[index_from]);
        this.form.screens[index_parent].layout.children.splice(index_from, 1);
        this.form.screens[index_parent].layout.children.splice(index_to, 0, child_data);
      }
    },
  },
};
</script>

<style lang="scss">
  .feather-10 {
    width: 11px;
    height: 11px;
  }
</style>
